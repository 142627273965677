.blogs-wrapper {
    padding: 3vw 6vw;
    margin-top: -4vw;
}

.blogs-wrapper .slick-list {
    margin-left: 3vw;
}

.slick-prev {
    width: 2rem;
    height: 2rem;
}
.slick-next {
    width: 2rem;
    height: 2rem;
}

@media (max-width: 600px) {
    .blogs-wrapper {
        margin-top: 0;
    }

    .blogs-wrapper .slick-list {
        margin-left: 8vw;
    }
}
