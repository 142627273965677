/* Custom styles for code blocks */
code {
    background-color: #f5f5f5; 
    padding: 2px 4px;
    border-radius: 4px;
    color: #333; 
}

pre code {
    background-color: #f0f0f0;
    color: #555; /* Lighter color for code text */
    padding: 16px;
    border-radius: 4px;
    display: block;
    overflow-x: auto; 
}
