.tfp-ribbon-container{
    padding: 0.972vw 0vw;
    background: #FACA01;
}
.tfp-ribbon-wrapper{
    text-decoration: none;
    color: #FFFFFF;
}
.tfp-announcement-content{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.tfp-announcement-logo-item{
    display: flex;
    align-items: center;
    gap: 0.833vw;
}
.tfp-announcement-logo-item-head{
    font-family: Gilroy-Bold;
    font-size: 1.389vw;
    font-weight: 400;
    line-height: 1.944vw;
    letter-spacing: 0.02em;
    text-align: left;
    width: 14.583vw;
    color: #0F172A;
}
.tfp-announcement-content-head{
    font-family: Gilroy-Bold;
    font-size: 1.667vw;
    font-weight: 400;
    line-height: 2.222vw;
    letter-spacing: 0.02em;
    text-align: left;
    color: #CD0002;
}
.tfp-announcement-content-head:hover{
    text-decoration: underline;
}
.tfp-announcement-content-para{
    text-decoration: none;
    margin-top: 0.278vw;
    font-family: Gilroy-Medium;
    font-size: 1.111vw;
    font-weight: 400;
    line-height: 1.667vw;
    letter-spacing: 0.02em;
    text-align: center;
    color: #020617;
}
.tfp-announcement-content-para-2{
    display: none;
}
.tfp-ribbon-claim-now-btn{
    padding: 0.556vw 1.944vw;
    border-radius: 0.694vw;
    background: #CD0002;
    border: none;
    font-family: Gilroy-Bold;
    font-size: 0.972vw;
    font-weight: 400;
    line-height: 1.667vw;
    letter-spacing: 0.02em;
    color: #F8FAFC;
    cursor: pointer;
}
@media only screen and (max-width: 600px) {
    .tfp-ribbon-container{
        padding: 3.733vw 3.2vw;
    }
    .tfp-ribbon-claim-now-btn{
        display: none;
    }
    .tfp-announcement-logo-item-head{
        display: none;
    }
    .tfp-announcement-content-head{
        font-size: 4.2vw;
        line-height: 5.333vw;
        text-decoration: none;
    }
    .tfp-announcement-content-para{
        margin-top: 1.067vw;
        font-size: 3.2vw;
        line-height: 4.267vw;
    }
    .tfp-rocket-icon-img{
        width: 13.333vw;
        margin-right: 1.067vw;
    }
    .tfp-announcement-content-para-1{
        display: none;
    }
    .tfp-announcement-content-para-2{
        display: block;
    }
}