.courses-wrapper {
    padding: 3vw 6vw;
}
.courses-wrapper .slick-list {
    margin-left: 3vw;
}

@media (max-width: 600px) {
    .courses-wrapper{
        margin-top: 15vw;
    }
    .courses-wrapper .slick-list {
        margin-left: 8vw;
    }

    .slick-prev {
        left: -10px;
    }
    .slick-next {
        right: -10px;
    }
}
