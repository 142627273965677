.add-blog-form-wrapper {
    display: flex;
    flex-direction: row;
}
.form-input-title {
    font-family: "Lato-Regular";
    font-size: 1.1vw;
    color: #343a40;
    margin-right: 0.5vw;
    padding: 1.5vw 0 1vw 0;
}
.get-time-date-wrapper {
    display: flex;
    flex-direction: column;
}

.add-blog-form-wrapper input {
    font-family: "Lato-Regular";
    width: 90%;
    height: 3vw;
    border: 1px solid rgba(68, 75, 171, 0.1);
    outline-color: #c7c7d1;
    text-indent: 10px;
}
.not-an-admin-section {
    font-size: 1.4vw;
    font-family: "Lato-Regular";
    color: brown;
    padding: 2vw 1vw;
}
.url-path {
    display: flex;
}

.add-blog-description {
    width: 60%;
    padding-left: 2vw;
    padding-right: 2vw;
}

.add-blog-additional-info {
    width: 40%;
    border-left: 2px solid #f8f7f7;
    padding-bottom: 2.5vw;
    right: 0%;
}

.add-blog-additional-info-title {
    background-color: #f8f7f7;
    font-size: 1.2vw;
    padding-left: 2.5vw;
    margin-left: 0.1vw;
    margin-top: 0.2vw;
    padding-top: 1vw;
}
.publish-blog-button-wrapper {
    display: flex;
    justify-content: flex-end;
}

.publish-blog-button {
    margin: 0.8vw 2.5vw 0.8vw 0;
    font-family: "Lato-Regular";
    color: #444bab;
    font-weight: bolder;
    border: 2px solid #444bab;
    border-radius: 6px;
    padding: 0.5vw 1vw;
}

.publish-blog-button:hover {
    cursor: pointer;
}
.edit-blog-button {
    border: none;
    background-color: #f8f7f7;
    padding-right: 3vw;
    padding-left: 3vw;
    font-family: "Lato-Regular";
    color: #444bab;
    font-weight: bold;
    cursor: pointer;
    font-size: 15px;
}
.blog-img-label {
    font-weight: 700;
    color: #343a40;
    cursor: pointer;
}
.blog-image-upload-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.5vw;
    margin-bottom: 0.5vw;
}
.blog-image-delete-btn {
    border: none;
    background-color: white;
    cursor: pointer;
    margin-right: 1vw;
}
.img-upload-wrapper {
    margin-top: 2vw;
    margin-right: 3vw;
    border: 2px solid #f8f7f7;
}
.blog-img-preview-txt {
    font-weight: 700;
    margin-left: 2vw;
}
