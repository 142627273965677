* {
    padding: 0;
    margin: 0;
    list-style: none;
    box-sizing: border-box;
}

* ::-webkit-scrollbar {
    width: 0px;
    display: none;
}

* ::-webkit-scrollbar-track {
    background: #fff;
    display: none;
}

* ::-webkit-scrollbar-thumb {
    background: #fff;
    display: none;
}

* ::-webkit-scrollbar-thumb:hover {
    background: #fff;
    display: none;
}

/* body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
} */
