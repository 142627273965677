/* Blog Detail Page Styles */
.blog-detail-page-contents {
    background-color: #fff;
    font-size: 1vw;
    padding-bottom: 3vw;
    padding-top: 3vw;
    font-family: "Poppins-Regular";
    line-height: 2.2vw;
    color: #231f20;
}
.blog-detail-page-contents h1,
.blog-detail-page-contents h2,
.blog-detail-page-contents h3 {
    font-size: 1.5vw;
    font-family: "Poppins-SemiBold";
    padding: 1vw 0vw;
}

.blog-detail-page-contents img {
    margin: 2vw 0vw;
}

/* Mobile adjustments */
@media (max-width: 600px) {
    .blog-detail-page-contents {
        font-size: 3vw;
        line-height: 6vw;
    }
    .blog-detail-page-contents h1,
    .blog-detail-page-contents h2,
    .blog-detail-page-contents h3 {
        font-size: 3.5vw;
        font-family: "Poppins-SemiBold";
        padding: 1vw 0vw;
    }
    .blog-detail-page-contents img {
        margin: 5vw 0vw;
    }
}

/* Share Icons Container */
.share-icons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;
    margin: 0 auto;
    width: 164px;
    height: 32px;
    margin-left: auto;  /* Pushes the icons to the right */
    margin-right: 5px;
}

/* General share icon styles */
.share-icon {
    width: 32px;
    height: 32px;
    background-color: #000000;  
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;  
    transition: background-color 0.3s ease;
}

/* Adjusting Facebook icon size */
.share-icon img {
    width: 17px;
    height: 17px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

/* Specific icon styles */
.share-icon.twitter { 
    order: 0; 
}
.share-icon.facebook { 
    order: 1; 
    background-color: #0D0F11;
}

.share-icon.linkedin { 
    order: 2; 
}
.share-icon.whatsapp { 
    order: 3; 
}

/* Hover effect for all icons */
.share-icon:hover {
    background-color: #333333;
}

/* Small screen adjustments */
@media (max-width: 600px) {
    .share-icon {
        width: 32px;
        height: 32px;
    }
    .share-icon img {
        width: 14px;
        height: 14px;
    }
}

/*Table of content scrollable*/
.scrollable-toc {
    position: relative;
    height: 200px; /* Set a specific height or max-height */
    overflow-y: auto; /* Allow auto scrollbars */
    padding-right: 10px; /* Optional: To make space for the scrollbar */
}

.scrollable-toc {
    position: relative;
    height: 200px; /* Set a specific height or max-height */
    overflow-y: auto; /* Allow auto scrollbars */
    padding-right: 10px; /* Optional: To make space for the scrollbar */
}

.scrollable-toc::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px; /* Height of the gradient */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    pointer-events: none; /* Allow clicks through the gradient */
}